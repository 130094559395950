import React from 'react'
import Particles from 'react-particles-js';
import Progress from 'components/progress'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faStackOverflow , faXing } from '@fortawesome/free-brands-svg-icons'
import AnimationContainer from 'components/animation-container'
import ThemeContext from '../../context'
import './styles.scss'
import data from '../../data'
//import {faDownload} from "@fortawesome/free-solid-svg-icons";

class Hero extends React.Component {

    static contextType = ThemeContext

    componentDidMount()
    {
        //window['forceReflowAdvanced'] = document.querySelector('#about').offsetHeight;
    }

    render() {
        return (
            <section id={`${this.props.id}`} className="about" style={{height: this.context.mobile ? 'auto' : this.context.height }}>
                {this.particles()}
                <Row>
                    <Col md={6} className="content">
                        <div className="content-text">
                            <div className="line-text">
                                <h4>About Me</h4>
                            </div>
                            <h3>Full Stack Web Developer</h3>
                            <div className="separator" />
                            {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus suscipit nisi vitae feugiat vestibulum. Aliquam porta nulla vel odio scelerisque, pretium volutpat dui euismod. Integer porttitor dolor placerat malesuada dictum. Fusce enim dolor, dignissim quis ornare at, elementum nec turpis. Donec ac interdum libero, sed condimentum lectus. Nunc nec iaculis tortor. Donec interdum sollicitudin eros in pharetra. Donec ultricies laoreet dictum. Maecenas vestibulum sodales justo, id hendrerit orci aliquet gravida. Nulla facilisi.</p>*/}
                            <div className="social social_icons">
                                <FontAwesomeIcon icon={faLinkedin} className="social_icon" onClick={() => window.open(data.social.linkedIn)} />
                                <FontAwesomeIcon icon={faXing} className="social_icon" onClick={() => window.open(data.social.xing)} />
                                <FontAwesomeIcon icon={faGithub} className="social_icon" onClick={() => window.open(data.social.github)}/>
                                <FontAwesomeIcon icon={faStackOverflow} className="social_icon" onClick={() => window.open(data.social.stackoverflow)} />
                            </div>
                            <div className="col-lg-6 personal-info-cont">

                                <div className="">
                                    <div className="col-lg-5 personal-info">
                                        <p className="text-white"><span className="font-weight-bold">Birthday :</span><br/> <span data-field="birthday" className="text-white">12th January 1992</span></p>
                                        <p className="text-white"><span className="font-weight-bold">Web :</span><br/><span data-field="website" className="text-white">maxw.io</span></p>
                                        <p className="text-white"><span className="font-weight-bold">Phone :</span><br/>
                                            <a style={{color:'#343a40'}}data-field="phone" href="tel:+49 151 529 111 11"> <span data-field="phone" className="text-white">+49 151 529 111 11</span></a>
                                        </p>
                                        <p className="text-dark"><span className="font-weight-bold">City :</span><br/><span data-field="location" className="text-white">Berlin, Germany</span></p>
                                    </div>
                                    <div className="col-lg-5 personal-info">
                                        <p className="text-white"><span className="font-weight-bold">Age :</span><br/><span data-field="age" className="text-white">32</span></p>
                                        <p className="text-white"><span className="font-weight-bold">Degree :</span><br/>
                                            <span data-field="degree" className="text-white">Master Computer Science</span>
                                        </p>
                                        <p className="text-white"><span className="font-weight-bold text-white">Mail :</span><br/> <a
                                            style={{color:'#343a40'}} data-field="email" href="mailto:weselmax@gmail.com"><span
                                            data-field="email" className="text-white">weselmax@gmail.com</span></a>
                                        </p>
                                    </div>


                                </div>




                            </div>

                            <AnimationContainer delay={250} animation="fadeInUp fast">
                            {<a download target="_blank" rel="noreferrer" href={"cv_max_wesel.pdf"}><button className="hover-button">
                                <span>Download CV</span>
                            </button></a>}
                            </AnimationContainer>

                        </div>
                    </Col>
                    <Col md={6} className="skills">
                        <div className="line-text">
                            <h4>My Skills</h4>
                        </div>
                        <div className="skills-container">
                            <Progress name="JavaScript" value={100} delay={1100} />
                            <Progress name="React.js" value={100} delay={1100} />
                            <Progress name="Progressive Web Apps" value={100} delay={1100} />
                            <Progress name="HTML5" value={100} delay={1100} />
                            <Progress name="Node.js" value={95} delay={1100} />
                            <Progress name="MySQL" value={85} delay={1100} />
                            <Progress name="Redis" value={85} delay={1100} />
                            <Progress name="Swift" value={75} delay={1100} />
                        </div>

                        <div className="line-text">
                            <h4 className="base-color mb-0">Other Technologies used:</h4>
                            <div style={{color:'#fff',fontSize:'16px'}}>WebGL, Three.js, Objective-C, Java, Vue.js, PHP, Google Closure, Redis, Gitlab-CI, Chrome Extensions, Shopify Apps</div>
                        </div>
                    </Col>
                </Row>
            </section>
        )
    }

    particles() {

        //return false;
        return (
            <Particles
                className="particles"
                params={{
                    "particles": {
                        "number": {
                            "value": 50,
                            "density": {
                                "enable": false,
                                "value_area": 5000
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "opacity": .5
                        },
                        "size": {
                            "value": 1
                        },
                    },
                    "retina_detect": true
            }}/>
        )
    }

}

export default Hero