import React from 'react'
import ReactDOM from "react-dom";
//import handleViewport from 'react-in-viewport'
import './styles.scss'

class Modal extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {};
        this.el = document.createElement('div');
        this.el.className = 'c-modal';
        document.querySelector('#___gatsby').prepend(this.el)
    }

    componentWillUnmount()
    {
        let portal = document.querySelector('.c-modal');
        console.log('componentWillUnmount',portal)
        if (portal) portal.parentNode.removeChild(portal);
    }

    render()
    {
        let dom = <div className="c-modal">
            <div className="window">
                {this.props.children}
            </div>
        </div>
        return ReactDOM.createPortal(dom, this.el);
    }
}

export default Modal