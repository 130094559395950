import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import AnimationContainer from 'components/animation-container'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faReact, faAngular, faAws } from '@fortawesome/free-brands-svg-icons'
import {
  //faPencilRuler,
  //faServer,
  //faRobot,
  faSmileBeam,
  faPizzaSlice,
  faQuoteRight,
  faCode,
} from '@fortawesome/free-solid-svg-icons'
import Counter from 'components/counter'
import ThemeContext from '../../context'
import './styles.scss'
//import {graphql, StaticQuery} from "gatsby";

class Cv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.show = this.show.bind(this)
  }

  static contextType = ThemeContext

  show() {
    this.setState({ show: true })
  }

  render() {

    return (
      <section
        id={`${this.props.id}`}
        className="cv"
        style={{ height: 'auto' }}
      >

        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText text={this.context.width < 991 ? "CV" : 'Curriculum Vitae'} revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
            </h2>
          </Col>
          <Col md={10} className="cv">
            {this.cv()}
          </Col>
        </Row>
        {/*<Row className="bottom">{this.counters()}</Row>}*/}
      </section>
    )
  }
  
  cv ()
  {
    //console.log('this.props.icons.',this.props.icons);
    return <div className="row mt-4">
      <div className="col-lg-6">
        <h3 className="text-white mb-2 mt-5 mt-lg-0"><i className="lni-pencil base-color"></i>Experience</h3>

        <ul className="timeline">
          <li>
            <h6 className="base-color mb-0">Founder & CEO LameTrain </h6>
            <small className="text-muted ">2019 - 2021</small>
            <p className="text-white py-3">Platform for fast and easy refunds in case of train delays

            </p>
            <span>Capabilities:</span>
            <ul>
              <li>Multi platform solution to request refunds</li>
              <li>Automated algorithm to evaluate requests</li>
              <li>Large scale train schedule analytics</li>
              <li>Dashboard and analytics</li>
            </ul>
            <br/><a target="_blank" rel="noreferrer" href="https://lametrain.de">lametrain.de</a>
            <br/><b>Stack:</b> React.js, Ant Design, JavaScript, Node.js, MySQL, Redis, AWS, Swift
            <p></p>
          </li>
          <li>
            <h6 className="base-color mb-0">Founder & CEO Modrena</h6>
            <small className="text-muted ">2016 - 2018</small>
            <p className="text-white py-3">Software as a Service for driving schools
            </p>
            <span>Capabilities:</span>
            <ul>
              <li>Onboarding of new students</li>
              <li>Employee Management</li>
              <li>Theory lessons</li>
              <li>Signing</li>
              <li>Fleet management</li>
              <li>Document management</li>
              <li>Accounting</li>
              <li>Dashboard and analytics</li>
            </ul>
            <br/><a target="_blank" rel="noreferrer" href="https://modrena.de">modrena.de</a>
            <br/><b>Stack:</b> React.js, Custom UI Framework, JavaScript, Node.js, MySQL, AWS, Swift
            <br/>
            <p></p>
          </li>
          <li>
            <h6 className="base-color mb-0">Electrip, Freelance Project</h6>
            <small className="text-muted ">2017 - today</small>
            <p className="text-white py-3">Software as a Service for electrology (hair removal)
            </p>
            <span>Capabilities:</span>
            <ul>
              <li>Customer management</li>
              <li>Treatments</li>
              <li>Appointments</li>
              <li>Receipts</li>
              <li>Dashboard and analytics</li>
            </ul>
            <br/><a target="_blank" rel="noreferrer" href="https://electrip.de/flyer">electrip.de</a>
            <br/><b>Stack:</b> React.js, Ant Design, JavaScript, Node.js, MySQL, AWS
            <p></p>
          </li>
          <li>
            <h6 className="base-color mb-0">StoreShow, Freelance Project</h6>
            <small className="text-muted ">2018 - today</small>
            <p className="text-white py-3">Digital signage for bakeries
            </p>
            <span>Capabilities:</span>
            <ul>
              <li>Uses Single Board Computers (SBCs) like Raspberries and Thinkerboards</li>
              <li>Distribute images and videos simualtaneously accross multiple premises</li>
              <li>Auto Update</li>
              <li>Dashboard and analytics</li>
            </ul>
            <br/><a target="_blank" rel="noreferrer" href="https://storeshow.de">storeshow.de</a>
            <br/><b>Stack:</b> Vanilla JS, Node.js, Tinkerboard OS, MySQL, AWS, Google Cloud
            <p></p>
          </li>
          <li>
            <h6 className="base-color mb-0">Intern SAP, Palto Alto, CA, USA</h6>
            <small className="text-muted "> June 2013 - Nov 2013</small>
            <p className="text-white py-3">
            </p>
            <ul>
              <li>Lead Developer Dashboard Analytics Tool, <a
                  href="https://patents.justia.com/inventor/philipp-maximilian-wesel">Patent</a> granted
              </li>
              <li>Lead Developer Internal Project Management Tool, Staffit</li>
            </ul>

            <br/><b>Tech:</b> SAP HANA, SAP UI5, WebGL, JavaScript, PHP
            <p></p>
          </li>
          <li>
            <h6 className="base-color mb-0">Intern Inter Component Ware, Walldorf, BW, Germany</h6>
            <small className="text-muted "> July 2011 - August 2011</small>
            <p className="text-white py-3">
            </p>
            <ul>
              <li>Basics of Java</li>
              <li>Created new employee handbook</li>
              <li>Designed the companies contact page</li>
              <li>Basics of SCRUM</li>
            </ul>
            <p></p>
          </li>
        </ul>
      </div>

      <div className="col-lg-6">
        <h3 className="text-white mb-2"><i className="lni-graduation base-color"></i>Education</h3>
        <ul className="timeline">
          <li>
            <h6 className="base-color mb-0">Master Computer Science</h6>
            <small className="text-muted ">2016 - 2020</small>
            <p className="text-white py-3">Humboldt University Berlin</p>
            <ul>
              <li>Master Thesis: Are Progressive Web Apps an alternative to native apps?</li>
            </ul>
          </li>
          <li>
            <h6 className="base-color mb-0">Bachelor Information Management</h6>
            <small className="text-muted ">2011 - 2015</small>
            <p className="text-white py-3">University of Applied Science Cologne (TH)</p>
            <ul>
              <li>Bachelor Thesis: New opportunities for collaboration and moderation for students through real time analytics in lectures</li>
              <li>Semester abroad at SAP, Palo Alto, CA <span className="emph">(see Experience)</span></li>
              <li>Tutor for lectures on HTML5, SVG, CSS and JS</li>
              <li>Student research assistant</li>
            </ul>
          </li>
          <li>
            <h6 className="base-color mb-0">Abitur</h6>
            <small className="text-muted ">2002 - 2011</small>
            <p className="text-white py-3">Heisenberg Grammar School, Ettlingen, BW, Germany</p>
          </li>
          <li>
            <h6 className="base-color mb-0">Schoolyear abroad</h6>
            <small className="text-muted ">Jan 2009 - July 2009</small>
            <p className="text-white py-3">6 months abroad in Brisbane (Australia) at Cleveland District State High School</p>
          </li>
        </ul>
      </div>

    </div>
  }

  counters() {
    if (this.state.show || this.context.height === 'auto') {
      return (
          <Container>
            <Col md={3}>
              <AnimationContainer delay={100} animation="fadeIn fast">
                <Counter
                    icon={faSmileBeam}
                    value={100}
                    text="Happy Clients"
                    symbol="+"
                    duration={3}
                />
              </AnimationContainer>
            </Col>
            <Col md={3}>
              <AnimationContainer delay={100} animation="fadeIn fast">
                <Counter
                    icon={faPizzaSlice}
                    value={1000}
                    text="Pizzas Ordered"
                    symbol="+"
                    duration={3}
                />
              </AnimationContainer>
            </Col>
            <Col md={3}>
              <AnimationContainer delay={100} animation="fadeIn fast">
                <Counter
                    icon={faQuoteRight}
                    value={500}
                    text="Reviews"
                    symbol="+"
                    duration={3}
                />
              </AnimationContainer>
            </Col>
            <Col md={3}>
              <AnimationContainer delay={100} animation="fadeIn fast">
                <Counter
                    icon={faCode}
                    value={50000}
                    text="Lines of Code"
                    symbol="+"
                    duration={3}
                />
              </AnimationContainer>
            </Col>
          </Container>
      )
    }
  }
}

export default Cv
/*export default props => (
    <StaticQuery
        query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }
    `}
        render={({ icons, Img }) => <Cv icons={icons} />}
    />
)*/