import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import Spinner from 'components/spinner'
import Hero from 'sections/hero'
import About from 'sections/about'
import Portfolio from 'sections/portfolio'
//import Services from 'sections/services'
//import Testimonials from 'sections/testimonials'
import Contact from 'sections/contact'
import Cv from 'sections/cv'


class HomePage extends React.Component {

  render() {
    const { site } = this.props.data
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
            <meta name="description" content={site.meta.description} />
            <meta name="viewport" content="width=device-width, initial-scale = 1.0, maximum-scale = 1.0, user-scalable = no, viewport-fit=cover"/>
        </Helmet>
        <Layout>
            <Hero id="home" />
            <About id="about" />
            <Cv id="cv" />
            <Portfolio id="portfolio" />
            <Contact id="contact" />
        </Layout>
        <Spinner duration={1000} />
      </div>
    )
  }
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`
