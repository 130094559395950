import React from 'react'
import { ThemeProvider } from '../../context'
import Navigation from 'components/navigation'
//import ScrollLock from 'react-scrolllock'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'scss/retro.scss'
//var scrollToElement = require('scroll-to-element')

class Layout extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = {
      height: 0,
      mobile: false,
      scrolllock: 0,
      width: 0,
    };
    this.sections = [
      'home',
      'about',
      //'services',
      'cv',
      'portfolio',
      //'testimonials',
      'contact',
    ]
    this.section_id = 0;
    this.scrolling = false;
    this.changeSection = this.changeSection.bind(this)
  }

  updateDimensions()
  {
    //return;
    //console.log('updateDimensions', window.innerWidth, window.innerHeight, 'isScrolling', this.scrolling);
    if (this.scrolling) // prevent resize events while user scrolls
    {
      return
    }
    if (this.state.width !== window.innerWidth)
    {
      window.location.reload()
    }
    this.setState({ height: window.innerHeight, width: window.innerWidth }); // Be Carefull could cause scroll hickups
    if (window.innerWidth < 992)
    {
      this.setState({mobile: true})
    }
  }

  setDefaults()
  {
    this.setState({
      height: window.innerWidth < 992 ? 'auto' : window.innerHeight,
      mobile: window.innerWidth < 992,
      scrolllock: false,
      innerHeight: window.innerHeight,
      width: window.innerWidth,
    })
  }

  componentDidMount()
  {
    this.setDefaults()
    this.setState({initialMountHeight: window.innerHeight})
    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  componentWillUnmount()
  {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('scroll', this.updateDimensions.bind(this));
  }

  changeSection(id)
  {
    this.section_id = id
  }

  onScroll(e)
  {
    //console.log('onScroll',document.scrollingElement.scrollTop);
    this.scrolling = true;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout( () => { this.scrolling = false  },300);
  }

  wheel(e)
  {
    return false
  }

  render() {
    const { children } = this.props;
    //let height = this.state.mobile ? 'auto' : this.state.height; // old solution for scrolllock anim
    let height = this.state.height;
    return (
      <ThemeProvider
        value={{ height: height,initialMountHeight:this.state.initialMountHeight, minHeight:height, width:this.state.width, mobile: this.state.mobile }}
      >
        <Navigation change={this.changeSection} />
        <div onWheel={this.wheel.bind(this)}>{children}</div>
        {/*<ScrollLock isActive={this.state.scrolllock} />*/}
      </ThemeProvider>
    )
  }
}

export default Layout
