import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'

import Tilt from 'react-tilt'
import ThemeContext from '../../context'
import Modal from '../../components/modal/index'
//import data from "../../data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faLink } from '@fortawesome/free-solid-svg-icons'
class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    const { items } = this.props
    this.state = {
      activeIndex:0,
      category: null,
      col:
        items.length > 6
          ? 4
          : items.length > 4
          ? 3
          : items.length > 3
          ? 2
          : items.length > 1
          ? 2
          : 1,
      items: this.props.items,
      showPortfolio: false,
    }
    this.keyUp = this.onKeyUp.bind(this);
    this.showPortfolio = this.showPortfolio.bind(this)

    //this.bound_onResize = this.onResize(bind);
    //this.onResize = this.onResize.bind(this);
  }
  static contextType = ThemeContext


  componentDidMount()
  {
     document.body.addEventListener('keyup', this.onKeyUp.bind(this));
  }

  componentWillUnmount()
  {
    //document.body.removeEventListener('keyup', this.keyUp.bind(this));
  }

  onKeyUp (e)
  {
    let comp = this;
     //console.log('onKeyUp',e.key,comp);
     if (comp.state.showSlideShow)
     {
       if (e.key === 'ArrowLeft')
       {
         comp.onChangeImage('prev');
       }
       if (e.key === 'ArrowRight')
       {
         comp.onChangeImage('next');
       }
     }
  }

  showPortfolio() {
    this.setState({ showPortfolio: true })
  }

  calcMaxHeightCont ()
  {
    return 'auto';
    //return  this.context.height !== 'auto' ? this.context.height * 0.8 : 'inherit'
  }

  onClickItem (item,index)
  {
    this.setState({showSlideShow:true,activeIndex:index})
  }

  getMedia ()
  {
    const { items,activeIndex } = this.state;
    let item = items[activeIndex];
    //console.log('activeIndex',activeIndex,item);
    if (item && item.content.frontmatter.imageCover)
    {
      //let childImageSharp = item.content.frontmatter.imageCover.childImageSharp;
      let src = item.content.frontmatter.imageCover.publicURL;
      //if (childImageSharp.fixed && childImageSharp.fixed.src)
      if (src)
      {
        if (item.content.frontmatter.link.indexOf('.mp4') !== -1)
        {
          return <video aria-label="Video"  controls onLoadedMetadata={this.onMediaReady.bind(this)} style={{height:this.context.height * 0.9}} className="media" src={item.content.frontmatter.link}></video>
        }
        //let src = childImageSharp.fixed.src
        return <img aria-label="Image" alt={"Portfolio Image "+src}  onLoad={this.onMediaReady.bind(this)} style={{maxHeight:this.context.height * 0.9}} className="media" src={src}></img>
      }
    }
  }

  onCloseSlideShow ()
  {
    this.setState({showSlideShow:false})
  }

  onMediaReady ()
  {
    this.setState({mediaLoading:false})
  }

  onChangeImage (dir)
  {
    //console.log('onChangeImage',dir);
    let {activeIndex, items} = this.state;
    let newIndex = dir === 'next' ? activeIndex + 1 : activeIndex -1;
    if (newIndex < 0) newIndex = items.length -1;
    if (newIndex >= items.length) newIndex = 0;
    //console.log('newIndex',newIndex);
    this.setState({activeIndex:newIndex, mediaLoading:true}, () => {})
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="portfolio"
        style={{ height: 'auto' }}
      >
        {this.state.showSlideShow ? <Modal>
          <div className="c-modal-body">
            <div className="content">
              <div className="num">{this.state.activeIndex +1}/{this.state.items.length}</div>
              {this.state.mediaLoading ? <div className="loader">Loading...</div> : false}
              {this.getMedia()}
            </div>
            <button aria-label="Close Button"  onClick={this.onCloseSlideShow.bind(this)} className="close">x</button>
            <button aria-label="Previous Button"  onClick={this.onChangeImage.bind(this,'prev')} className="arrow arrow-left"></button>
            <button aria-label="Next Button"  onClick={this.onChangeImage.bind(this,'next')} className="arrow arrow-right"></button>
          </div>
        </Modal> : false}
        <Row>
          <Col md={2} className="side">
            <h2>
              <BaffleText
                text="Portfolio"
                revealDuration={500}
                revealDelay={500}
                parentMethod={this.showPortfolio}
                callMethodTime={1100}
              />
            </h2>
          </Col>
          <Col md={10} className="recent-works">
            <div className="portfolio_selector">
              <button
                className="portfolio_category"
                onClick={() => this.changeCategory(null)}
              >
                <span className={`${!this.state.category ? 'active' : ''}`}>
                  All
                </span>
              </button>
              {this.categories()}
            </div>
            <div className="content">
              <div
                className="portfolio_container"
                style={{
                  maxHeight:this.calcMaxHeightCont(),
                }}
              >
                {this.items()}
              </div>
            </div>
          </Col>
        </Row>
      </section>
    )
  }

  calcWidth ()
  {
    //return this.context.width < 991 ? '20%';
    if (this.context.width < 500) return '100%';
    if (this.context.width < 991) return '50%';
    return '100%';
    //return '20%';
    /*return this.context.height === 'auto'
        ? this.context.width > 991 ? '33.3%' : '100%'
        : this.state.col === 4
            ? '25%'
            : this.state.col === 3
                ? '33.3%'
                : this.state.col === 2
                    ? '50%'
                    : '100%'*/
  }
  calcMaxHeight (value)
  {
    //return 'auto';
    return `${this.context.height *
    (this.state.col >= 3
        ? 0.35
        : this.getItemCount(
            value.content.frontmatter.category
        ) === 4
            ? 0.36
            : 1)}px`
  }

  items() {
    if (this.state.showPortfolio || this.context.height === 'auto') {
      const { items } = this.state
      //console.log('items',items);
      return items.map((value, index) => {
        if (
          value.content.frontmatter.category === this.state.category ||
          !this.state.category
        ) {
          if (value.content.frontmatter.image) {
            return (
              <div
                  role="figure"
                  onKeyUp={ () => {}}
                  onClick={this.onClickItem.bind(this,value,index)}
                className={"portfolio_item"+(value.content.frontmatter.title.indexOf('video') !== -1 ? ' video' : '')}
                style={{
                  width: this.calcWidth()}}
                key={index}
              >
                <AnimationContainer delay={200} animation="fadeIn" key={index}>
                  <img loading="lazy" src={
                      //value.content.frontmatter.image.childImageSharp.fluid.src
                      //value.content.frontmatter.image.childImageSharp.fixed.src
                      value.content.frontmatter.image.publicURL
                    }
                    alt={value.content.frontmatter.title}
                    style={{
                      maxHeight: this.calcMaxHeight.bind(this,value),
                    }}
                  />
                  {value.content.frontmatter.title.indexOf('video') !== -1 ? <div className="play"></div> : false}
                  <Tilt className="Tilt" options={{ scale: 1, max: 50 }}>
                    <div className="overlay">
                      <span className="title">
                        {value.content.frontmatter.title}
                      </span>
                      <br/>
                      <span className="link">
                          <a aria-label="Icon"  href={value.content.frontmatter.video || value.content.frontmatter.link} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLink} className="social_icon"/></a>
                      </span>
                    </div>
                  </Tilt>
                </AnimationContainer>
              </div>
            )
          }
        }
        return false
      })
    }
  }

  getItemCount(category) {
    let total = 0
    this.state.items.forEach(v => {
      if (v.content.frontmatter.category === category || !category) total++
    })
    return total
  }

  changeCategory(category) {
    const { items } = this.props
    this.setState({ items: [] })
    let total = 0
    items.forEach(v => {
      if (v.content.frontmatter.category === category || !category) total++
    })
    let col = total > 6 ? 4 : total > 4 ? 3 : total > 3 ? 2 : total > 1 ? 2 : 1

    this.setState({ category: category, col: col })
    setTimeout(() => {
      this.setState({ items: items })
    }, 50)
  }

  categories() {
    const { items } = this.props
    let categories = []
    for (var v of items) {
      categories.push(v.content.frontmatter.category)
    }
    categories = [...new Set(categories)]
    return categories.map((value, index) => {
      return (
        <button
          className="portfolio_category"
          onClick={() => this.changeCategory(value)}
          key={index}
        > 
          <span className={`${this.state.category === value ? 'active' : ''}`}>
            {value}
          </span>
        </button>
      )
    })
  }
}

export default props => (
  <StaticQuery
    query={graphql`
          query {
            items: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(portfolio)/"}}, sort: {fields: [frontmatter___id], order: ASC}, 
            # The layout is built for 6 portfolio items #
            limit: 12) {
              edges {
                content: node {
                  html
                  frontmatter {
                    id
                    title
                    category
                    link
                    imageCover {
                      publicURL
                      #childImageSharp {
                      #   fixed (width:3000) {
                      #    ...GatsbyImageSharpFixed
                      #  }
                      #}
                    }
                    image {
                      publicURL
                      #childImageSharp {
                        #fluid(maxWidth: 600, maxHeight: 600) {
                         # src
                        #}
                        #fixed (width:3000) {
                        #  ...GatsbyImageSharpFixed
                        #}
                      #}
                    }
                     
                  }
                }
              }
            }
          }      
        `}
    render={({ items }) => <Portfolio items={items.edges} {...props} />}
  />
)
