
let birthday = new Date(1992,0,12,0,18,0,0);
let now = new Date();
let age = now.getFullYear() - birthday.getFullYear();
let data = {
    introTop:'I\'M Max Wesel',
    intro:'Hello I\'M Max Wesel',
    name:'Max Wesel',
    birthday:'12 January 1992',
    age:age,
    phone:'+49 151 529 111 11',
    email:'weselmax@gmail.com',
    location:'Düsseldorf, Germany',
    website:'maxw.io',
    degree:'Master Computer Science',
    social:{
        xing:'https://www.xing.com/profile/Max_Wesel/cv',
        linkedIn:'https://www.linkedin.com/in/max-wesel-98066645/',
        github:'https://github.com/maxx-dev',
        stackoverflow:'https://stackoverflow.com/users/1887771/silicium'
    },
    portfolio:{
        lametrain:{
            title:'LameTrain',
            completedOn:'Still in development',
            skills:'JavaScript, React.js, Ant Design, MySQL, Redis, AWS, PWA, Swift',
        },
        modrena:{
            title:'LameTrain',
            completedOn:'Jan, 2018',
            skills:'React.js, Custom UI Framework',
        },
        electrip:{
            title:'LameTrain',
            completedOn:'Jan, 2018',
            skills:'JavaScript, React.js, Ant Design, MySQL, Node.js',
        },
        storeshow:{
            title:'StoreShow',
            completedOn:'Jan, 2018',
            skills:'Vanilla JavaScript, Material Design, MySQL, Tinker OS, Node.js',
        },
        codesnag:{
            title:'CodeSnag',
            completedOn:'Jan, 2018',
            skills:'Vanilla JavaScript, Swift, Webview',
        },
        quickreact:{
            title:'QuickReact',
            completedOn:'Jan, 2018',
            skills:'JavaScript, Google Closure, Java, Swift, Webview',
        }
    }
}
export default data;