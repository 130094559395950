import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            phone: "",
            message: "",
            error: false,
            show: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext


    show() {
      this.setState({show : true})
    }

    check(val) {
        if (this.state.error && val === "") {
                return false
        } else {
            return true
        }
    }

    submit() {
        if (this.state.name === "" || this.state.email === "" || this.state.message === "") {
            this.setState({error: true})
        } else {

            this.sendEmail();
            this.setState({error: false})
        }
    }

    sanitizeHTML(str) {
        var temp = document.createElement('div');
        temp.textContent = str;
        return temp.innerHTML;
    };

    sendEmail ()
    {
        let body = 'name='+this.sanitizeHTML(this.state.name)+'&email='+this.sanitizeHTML(this.state.email)+'&comments='+this.sanitizeHTML(this.state.message)+'&phone='+this.sanitizeHTML(this.state.phone);
        //console.log('body',body);
        let msg = document.querySelector('.msg');
        (async () => {
            const rawResponse = await fetch('sendEmail.php', {
                method: 'POST',
                headers:{
                    'Content-Type':'application/x-www-form-urlencoded'
                },
                body: body
            });
            msg.style.marginTop = '10px';
            msg.style.fontSize = '16px';
            try {
                const res = await rawResponse.json();
                //console.log('res',res);
                if(res['status'] === 'Congratulation')
                {
                    msg.style.color ='#5cb85c';
                    msg.innerHTML = '<strong>'+ res['status'] +' : </strong> '+ res['message'];
                }
                else if(res['status'] === 'Error')
                {
                    msg.style.color = '#fc0e0c';
                    msg.innerHTML = '<strong>'+ res['status'] +' : </strong> '+ res['message'];
                }
                else if(res['status'] === 'Warning'){
                    msg.style.color = '#fc0e0c';
                    msg.innerHTML = '<strong>'+ res['status'] +' : </strong> '+ res['message'];
                }
            }
            catch (e)
            {
                console.error(e);
                msg.style.color = '#fc0e0c';
                msg.innerHTML = '<strong> Error : </strong> Something went wrong, try again!';
            }
        })();

    }

    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{height: this.context.height, minHeight: this.context.minHeight}}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    <Col md={5} className="map">
                        {this.map()}
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                <div className="form-container">
                    <div className="line-text">
                        <h4>Get In Touch</h4>
                        {/*<AnimationContainer delay={50} animation="fadeInUp fast">
                            <div className="form-group">
                                <input aria-label="name" type="text" className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="Name" onChange={e => this.setState({name: e.target.value})} />
                            </div>
                        </AnimationContainer>
                        <AnimationContainer delay={100} animation="fadeInUp fast">
                        <div className="form-group">
                            <input aria-label="Email" type="text" className={`email ${this.check(this.state.email) ? "" : "error"}`} placeholder="Email" onChange={e => this.setState({email: e.target.value})} />
                        </div>
                        </AnimationContainer>
                        <AnimationContainer delay={150} animation="fadeInUp fast">
                            <div className="form-group">
                                <input aria-label="Phone" type="text" className="phone" placeholder="Phone" onChange={e => this.setState({phone: e.target.value})} />
                            </div>
                        </AnimationContainer>
                        <AnimationContainer delay={200} animation="fadeInUp fast">
                        <div className="form-group">
                            <textarea aria-label="Message" className={`message ${this.check(this.state.message) ? "" : "error"}`} placeholder="Message" onChange={e => this.setState({message: e.target.value})}></textarea>
                        </div>
                        </AnimationContainer>
                        <AnimationContainer delay={250} animation="fadeInUp fast">
                        <div className="submit">
                            <button className={`hover-button ${this.state.error ? "error" : ""}`} onClick={() => this.submit()}>
                                <span>Send Message</span>
                            </button>
                            <div className="msg"></div>
                        </div>
                        </AnimationContainer>*/}
                        <AnimationContainer delay={150} animation="fadeInUp fast">
                            <div className="form-group">
                                <h3><a style={{color:'#fff'}} href="mailto:contact@maxw.io">contact@maxw.io</a></h3>
                            </div>
                        </AnimationContainer>
                    </div>
                </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>
                    <iframe title="map" width="100%" height="100%" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=1%20Berlin,%20Germany&amp;ie=UTF8&amp;t=&amp;z=12&amp;iwloc=B&amp;output=embed"/>
                </AnimationContainer>
            )
        }
    }

}

export default Contact